import React, { MouseEventHandler, ReactNode } from "react";
import { ButtonProps } from "@mantine/core";
import { Colours } from "../colours";
import { GenericButton } from "./generic-button";

type OutlinedButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  sx?: ButtonProps["sx"];
  text?: string;
  disabled?: boolean;
  loading?: boolean;
  children?: ReactNode;
};

/**
 *
 * @param disabled if button is disabled
 * @param sx button styling
 * @param onClick function called when button is clicked
 * @param text shown inside button
 * @param loading is action initiated by button is currently loading
 * @param children any child components
 * @returns
 */
export const OutlinedButton = ({
  onClick,
  sx,
  text,
  disabled,
  loading,
  children,
}: OutlinedButtonProps) => {
  return (
    <GenericButton
      text={text || "Back"}
      onClick={onClick}
      sx={{
        backgroundColor: "white",
        color: Colours.darkBlue,
        borderColor: Colours.darkBlue,
        marginTop: "20px",
        ...sx,
      }}
      disabled={disabled}
      loading={loading}
      hoverStyles={{ backgroundColor: "white" }}
    >
      {children}
    </GenericButton>
  );
};
