import { useState, useEffect, Suspense } from "react";
import { useRouter } from "next/router";
import { Center, Loader } from "@mantine/core";

const DefaultLoading = () => {
  return (
    <Center sx={{ width: "100vw", height: "100vh" }}>
      <Loader size={100} sx={{ margin: "auto" }} />;
    </Center>
  );
};

const Layout = ({ children }) => {
  const router = useRouter();
  const [isRouteChanging, setIsRouteChanging] = useState(false);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setIsRouteChanging(true);
    };

    const handleRouteChangeComplete = () => {
      setIsRouteChanging(false);
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router.events]);

  return (
    <Suspense fallback={<DefaultLoading />}>
      {isRouteChanging ? <DefaultLoading /> : children}
    </Suspense>
  );
};

export default Layout;
