import { Button, ButtonProps, CSSObject } from "@mantine/core";
import { MouseEventHandler, ReactNode } from "react";
import { Colours } from "../colours";

type GenericButtonProps = {
  disabled?: boolean;
  loading?: boolean;
  sx?: ButtonProps["sx"];
  onClick?: MouseEventHandler<HTMLButtonElement>;
  hoverStyles?: CSSObject;
  text: string;
  children?: ReactNode | any;
};

/**
 * Generic button to be used on the application
 *
 * @param disabled if button is disabled
 * @param sx button styling
 * @param onClick function called when button is clicked
 * @param text shown inside button
 * @param loading is action initiated by button is currently loading
 * @param hoverStyles styles for when button is hovered
 * @param children any child components
 * @returns
 */
export const GenericButton = ({
  disabled,
  sx,
  onClick,
  text,
  hoverStyles,
  loading = false,
  children,
}: GenericButtonProps) => {
  return (
    <Button
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      sx={{
        textTransform: "none",
        fontSize: "13px",
        backgroundColor: Colours.darkBlue,
        color: "white",
        borderRadius: "13px",
        width: "160px",
        ":hover": {
          backgroundColor: Colours.darkBlue,
          ...hoverStyles,
        },
        ...sx,
      }}
    >
      {typeof children === "undefined" ? text : children}
    </Button>
  );
};
