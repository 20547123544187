import { ContextModalProps } from "@mantine/modals";
import { Text, Flex } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons";
import { OutlinedButton } from "./outlined-button";
import { GenericButton } from "./generic-button";

type Props = {
  message: string;
  onContinue?: () => void;
  onCancel?: () => void;
  disabledCancel: boolean;
};

/**
 *  Modal for showing warning or error messages
 * @param context of the modal
 * @param id of the modal
 * @param innerProps props passed into this modal being of type Props
 * @returns
 */
export const WarningModal = ({
  context,
  id,
  innerProps: { message, disabledCancel, onCancel, onContinue },
}: ContextModalProps<Props>) => (
  <>
    <Flex direction="column" align="center">
      <IconAlertTriangle width={67} height={59} color="red" />
      <Text
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          width: "315px",
          marginTop: "20px",
          textAlign: "center",
        }}
      >
        {message}
      </Text>
      <Flex direction="column" sx={{ marginTop: "20px" }}>
        <GenericButton
          text={disabledCancel ? "Close" : "Continue"}
          onClick={() => {
            context.closeModal(id);
            onContinue?.();
          }}
        />
        {!disabledCancel && (
          <OutlinedButton
            text="Cancel"
            onClick={() => {
              context.closeModal(id);
              onCancel?.();
            }}
            sx={{ marginTop: "10px", borderColor: "white", color: "#707070" }}
          />
        )}
      </Flex>
    </Flex>
  </>
);
